* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.about_us {
    background-color: white;
    .aboutus_container {
        .section {
            .header-container {
                background: linear-gradient(127.31deg, rgba(0, 0, 0, 0.68) 44.53%, rgba(0, 0, 0, 0) 83.52%), url(../../img/office.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                padding: 100px;
                margin: auto;

                .title-container {
                    padding-top: 100px;

                    li,
                    h1,
                    p {
                        color: white;
                    }

                    .title {

                        display: grid;
                        flex-direction: column;
                        gap: 12px;

                    }
                }

            }

            .container {


                .middle_section {
                    padding-top: 100px;


                    .image_text {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;


                        .text {
                            display: flex;
                            flex-direction: column;

                        }

                        .image {

                            width: fit-content;
                            object-fit: cover;

                            img {
                                width: 520px;
                            }


                        }

                    }

                    .misson {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 100px;

                        .image {
                            width: fit-content;

                            img {
                                width: 520px;
                            }
                        }





                    }
                }


                .vision_value {
                    gap: 30px;
                    padding-top: 100px;


                    .contents {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        justify-content: space-between;
                        // align-items: start;s
                        flex: 1;
                        gap: 12px;

                        .vision {
                            border-radius: 2px;
                            padding: 50px;
                            background-color: #2A2D7C;
                            color: #f6f9fe;
                        }

                        .images {
                            width: fit-content;

                            img {
                                height: 350px;
                                width: 400px;
                                object-fit: cover;
                            }
                        }
                    }

                    .values {
                        border-radius: 2px;
                        padding: 50px;
                        background-color: #2A2D7C;
                        color: #f6f9fe;
                    }
                }
            }
        }
    }


}




@media screen and (max-width:1250px) {
    .about_us {
        .aboutus_container {
            .section {
                .header-container {
                    padding: 20px;
                }

            }
        }
    }

}

@media screen and (max-width:900px) {
    .about_us {
        .aboutus_container {
            .section {
                .container {

                    .middle_section {
                        padding-top: 100px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 50px;

                        .image_text {
                            display: flex;
                            flex-direction: column;
                            gap: 50px;

                            .text
                            {
                                width: 100%;
                            }
                            

                            .image{
                                img{
                                    width: 100%;
                                }
                            }
                        }

                        .misson {
                            display: flex;
                            flex-direction: column-reverse;
                            gap: 50px;

                            .image{
                                img{
                                    width: 100%;
                                }
                            }

                        }
                    }

                    .vision_value {

                        .contents {
                            display: flex;
                            flex-direction: column-reverse;
                            gap: 50px;
                            align-items: flex-start;

                            .values {
                                width: 90%;


                            }

                            .vision {
                                width: 90%;
                            }

                            .images {
                                width: 100%;
                                img {
                                    width: 90%;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width:520px) {
    .about_us {
        
        .aboutus_container {
            
            .section {
                .who {
                    .middle_section {
                        .image_text {
                            .image {
                                img {
                                    width: 400px;
                                }
                            }
                        }

                        .misson {
                            .image {
                                img {
                                    width: 100%;
                                }

                            }
                        }
                    }



                }
            }
        }

    }
}