.our_team {
  padding-top: 50px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.our_team .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.our_team .popup .popup-content {
  background-color: #f6f9fe;
  padding: 45px;
  border-radius: 2px;
  width: 800px;
  gap: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: 80vh;
  overflow-y: auto;
}
.our_team .popup .popup-content .popup-info .col .popup-image {
  width: 100px;
  height: auto;
}
.our_team .popup .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}
.our_team .row {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.our_team .row .title {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
}
.our_team .row .people {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.our_team .row .people .first_row {
  display: flex;
  gap: 32px;
}
.our_team .row .people .first_row .img_text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.our_team .row .people .first_row .img_text .col {
  display: flex;
  gap: 24px;
}
.our_team .row .people .first_row .img_text .col .pic_title p {
  color: #2A2D7C;
}
.our_team .row .people .first_row .img_text .col img {
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (max-width: 900px) {
  .our_team .popup .popup-content {
    width: 400px;
  }
  .our_team .people .first_row {
    flex-direction: column;
    gap: 34px;
  }
}
@media screen and (max-width: 360px) {
  .our_team .popup .popup-content {
    width: 300px;
  }
}/*# sourceMappingURL=ourteam.css.map */