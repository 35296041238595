@import '../../variables/vairables.css';
@import "../../main/main.css";
.coming-soon {
  height: 100vh;
  background: linear-gradient(127.31deg, rgba(0, 0, 0, 0.5) 44.53%, rgba(40, 39, 39, 0.5) 83.52%), url("/public/assets/img/coming-soon/codes.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
}
.coming-soon .container {
  max-width: 100%;
}
.coming-soon .container .row {
  display: flex;
  align-items: center;
}
.coming-soon .container .row .col {
  width: 50%;
}
.coming-soon .container .row .col-1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.coming-soon .container .row .col-1 .logo {
  max-width: 250px;
}
.coming-soon .container .row .col-1 .logo img {
  width: 100%;
}
.coming-soon .container .row .col-2 {
  height: 100vh;
  background-color: white;
  display: grid;
  place-content: center;
  gap: 24px;
  padding: 0 90px;
  margin: auto;
}
.coming-soon .container .row .col-2 h1 {
  color: #2A2D7C;
  text-transform: capitalize;
  font-family: "Redwing light";
}
.coming-soon .container .row .col-2 .coming-soon-contacts {
  display: flex;
  gap: 24px;
}
.coming-soon .container .row .col-2 .coming-soon-contacts .col-contacts {
  display: flex;
  align-items: center;
  gap: 12px;
}
.coming-soon .container .row .col-2 .social-media {
  display: flex;
  gap: 12px;
}
.coming-soon .container .row .col-2 .social-media a {
  color: white;
}

@media screen and (max-width: 540px) {
  .coming-soon .container {
    width: 100%;
    padding: 0;
  }
  .coming-soon .container .row .col-1 {
    display: none;
  }
  .coming-soon .container .row .col-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 20px;
    text-align: justify;
    place-items: center;
    place-content: center;
  }
  .coming-soon .container .row .col-2 .coming-soon-contacts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
  }
}/*# sourceMappingURL=comingsoon.css.map */