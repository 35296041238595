@import '../../variables/vairables.css';
@import "../../main/main.css";
.hero-social-media {
  background-color: #F6F9FE;
  padding-top: 40vh;
  color: #2A2D7C;
  text-align: start;
  position: relative;
}
.hero-social-media .container .row {
  display: flex;
  align-items: center;
  gap: 24px;
}
.hero-social-media .container .row .col-social {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.hero-social-media .container .row .col {
  width: 50%;
}
.hero-social-media .container .row .col img {
  position: absolute;
  width: 40%;
  right: 0;
  top: 190px;
}
.hero-social-media .container .social-media {
  cursor: pointer;
}
.hero-social-media .container .social-media img {
  width: 50px;
  height: 50px;
}
.hero-social-media .container .social-media .facebook {
  position: absolute;
  top: 24vh;
  left: 63vw;
}
.hero-social-media .container .social-media .instagram {
  position: absolute;
  top: 56vh;
  left: 58vw;
}
.hero-social-media .container .social-media .linkedin {
  position: absolute;
  top: 38vh;
  left: 60vw;
}
.hero-social-media .container .social-media .youtube {
  position: absolute;
  top: 72vh;
  left: 55vw;
}
.hero-social-media .container .social-media .twitter {
  position: absolute;
  top: 90vh;
  left: 50vw;
}
.hero-social-media .container .social-media .twitter #spin :hover {
  animation: bounce 0.9s linear;
}
.hero-social-media .container .pattern {
  position: absolute;
  width: 267px;
  height: 311px;
  left: 0;
  top: 700px;
}

.social-media-services {
  background-color: #f6f9fe;
  padding-top: 150px;
}
.social-media-services .container .row {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  align-items: start;
  gap: 5%;
}
.social-media-services .container .row .col {
  width: 100%;
  border-radius: 2px;
  margin: 24px 0;
  display: grid;
  gap: 24px;
}
.social-media-services .container .row .col:hover {
  padding: 24px;
  background-color: rgba(52, 97, 226, 0.13);
  transition: 0.3s;
  border-radius: 2px;
  color: #3d3d3d;
}

.social-media-section {
  background-color: #F6F9FE;
  padding: 150px 0 50px 0;
}
.social-media-section .container .row {
  display: flex;
  align-items: center;
  gap: 96px;
}
.social-media-section .container .row .col {
  width: 50%;
}
.social-media-section .container .row .col img {
  width: 100%;
}

@media screen and (max-width: 950px) {
  .container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .container .row .hero-heading {
    font-size: 32px;
  }
}
@media screen and (max-width: 760px) {
  .hero-social-media .container .social-media {
    display: grid;
    gap: 12px;
  }
  .hero-social-media .container .social-media img {
    width: 34px;
    height: 34px;
  }
  .hero-social-media .container .social-media .facebook {
    position: relative;
    left: 50vw;
    top: -450px;
  }
  .hero-social-media .container .social-media .instagram {
    position: relative;
    left: 50vw;
    top: -450px;
  }
  .hero-social-media .container .social-media .linkedin {
    position: relative;
    left: 50vw;
    top: -450px;
  }
  .hero-social-media .container .social-media .youtube {
    position: relative;
    left: 50vw;
    top: -450px;
  }
  .hero-social-media .container .social-media .twitter {
    position: relative;
    left: 50vw;
    top: -450px;
  }
  .hero-social-media .container .pattern {
    display: none;
  }
  .social-media-services .container .row {
    grid-template-columns: 47% 47%;
    row-gap: 32px;
  }
}
@media screen and (max-width: 540px) {
  .hero-social-media {
    padding-top: 25vh;
  }
  .hero-social-media .container {
    position: relative;
  }
  .hero-social-media .container .row {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .hero-social-media .container .row .col {
    width: 100%;
  }
  .hero-social-media .container .row .col img {
    position: relative;
    width: 100%;
    top: 0;
    right: -20px;
  }
  .hero-social-media .container .social-media {
    display: grid;
    gap: 12px;
    position: absolute;
    bottom: 160px;
  }
  .hero-social-media .container .social-media .facebook {
    position: relative;
    left: 0;
    top: 0;
    width: 32px;
    height: auto;
  }
  .hero-social-media .container .social-media .instagram {
    position: relative;
    left: 0;
    top: 0;
    width: 32px;
    height: auto;
  }
  .hero-social-media .container .social-media .linkedin {
    position: relative;
    left: 0;
    top: 0;
    width: 32px;
    height: auto;
  }
  .hero-social-media .container .social-media .youtube {
    position: relative;
    left: 0;
    top: 0;
    width: 32px;
    height: auto;
  }
  .hero-social-media .container .social-media .twitter {
    position: relative;
    left: 0;
    top: 0;
    width: 32px;
    height: auto;
  }
  .social-media-services .container .row {
    margin-top: 50px;
    grid-template-columns: 100%;
    gap: 32px;
  }
  .social-media-services .container .row .col {
    margin: 0;
    background-color: rgba(52, 97, 226, 0.13);
    padding: 30px;
  }
  .social-media-services .container .row .col:hover {
    margin: 0;
    padding: 30px;
    background-color: white;
    transition: 0.3s;
    border-radius: 2px;
    color: #3d3d3d;
  }
  .social-media-section {
    padding: 50px 0;
  }
  .social-media-section .container .row {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .social-media-section .container .row .col {
    width: 100%;
  }
}/*# sourceMappingURL=social-media.css.map */