*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    background-color: #f5f7fb;
    font-family: "Futura Book", sans-serif;
    color: #3d3d3d;
    font-size: 20px;
}

.hero-1{
    .hero-container{
        padding-bottom: 60px;
        display: flex;
        padding-inline: 70px;
        // padding-right: 0;
        gap: 50px;
        align-items: center;
        .software-text{
            .paragraph{
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                unicode-bidi: isolate;
            }

            .header-dev{
                font-size: 45px;
                font-weight: bolder;
                font-family: 'Redwing, medium'
            
              }

        }

        .develoment-image{
            width: 100%;
            img{
                width: 100%;
                height: 100%;
            }
          
                       
        }
    }

    .card-services{
        margin-top: 35px;
        padding: 60px;
        .container-service{
            margin-top: 70px;
            display: flex;
            flex-direction: row;
            gap: 30px;

            >div{
                transition: .3s ease-out;
                padding-block: 10px;
                max-width: 400px;
                width: 100%;
                
                &:hover{
                    background-color: #4365AF;
                    padding-left: 20px;
                    color: #ffffff;

                    i{
                        background-color: #ffffff;
                        color: #2a2d7c;

                    }
                }
            }

     

           h3{
            display: block;
            font-size: 1.17em;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
            unicode-bidi: isolate;
           }

           i{
            height: 50px;
            transition: .3s ease-out;

    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2a2d7c;
    color: white;
    border-radius: 2px;
           }

        }

        .title-service{
            font-size: 45px;
            font-weight: bolder;
            font-family: "Redwing, medium";
        }
       
    }
    .section-what-we-do{
        margin-top: -149px;
        padding: 60px 60px 60px;

        .what-we-offer{
            font-size: 45px;
            font-weight: bold;
            font-family: "Redwing",
        }

        .paragrap-we-offer{
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            unicode-bidi: isolate;
        }
    }


    .blue-background{
        padding: 60px;
        padding-top: 500px;
        padding-bottom: 200px;
        max-width: 100%;
        background: linear-gradient(180deg, rgba(42, 45, 124, 0.4) 44.53%, rgb(42, 45, 124) 83.52%), url("/public/assets/img/software-dev/accounting-services.jpg");
        .container-cta{
            max-width: 1200px;
            padding: 0;
            margin: auto;
           
            .blue-container{
                flex-direction: column;
                display: flex;
                gap: 24px;
                .whitetext{
                    color: white;
                }
                .white-paragraph{
                    color: white;
                }
            }

        }
        .btn-white{
            width: fit-content;
        }
       
    }




    .process-section{
        padding: 60px;
        background-color: #2A2D7C;
        color: white;
        position: relative;
        padding-bottom: 100px;
        

        .Process-container{
            display: flex;
            flex-direction: column; 
            align-items: center; /
            .Process-text {
                .single-process {
                    display: flex;
                    align-items: start;
                    gap: 24px;
                    height: auto; 
                    padding-top: 20px;  
                    .number {
                        background-color: #f6f9fe;
                         color: #2A2D7C;
                         min-height: 40px;
                         min-width: 40px;
                         display: flex;
                         align-items: center;
                        justify-content: center;
                         border-radius: 2px;

                    }
                    .process-paragraph{
                        width: 65%;
                    }
                }

            }

            .single-image{
                .image-container{
                    img {
                        position: absolute;
                         bottom: 0;
                         right: 0;
                         width: 38%;
                    }
                  
                }
            }
        }

        .haeding-proces{
            font-size: 45px;
            font-weight: bolder;
            font-family: "Redwing, medium";
        }
    }
    
}




/* Media Queries */

@media screen and (min-width: 1440px) {
    .hero-1 .hero-container {
        padding-inline: 12%;
        gap: 12%;
    }
    .card-services .container-service {
        gap: 10%;
    }
    .section-what-we-do {
        padding-inline: 25%;
    }
    .blue-background {
        padding: 12%;
        padding-top: 350px;
        padding-bottom: 160px;
    }
    .process-section {
        padding: 12%;
        padding-bottom: 120px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .hero-1 .hero-container {
        gap: 10%;
    }
    .card-services .container-service {
        gap: 7%;
    }
    .section-what-we-do {
        padding-inline: 20%;
    }
    .blue-background {
        padding: 10%;
        padding-top: 300px;
        padding-bottom: 140px;
    }
    .process-section {
        padding: 10%;
        padding-bottom: 100px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .hero-1 .hero-container {
        padding-inline: 8%;
        gap: 8%;
    }
    .card-services .container-service {
        gap: 5%;
    }
    .section-what-we-do {
        padding-inline: 15%;
    }
    .blue-background {
        padding: 8%;
        padding-top: 250px;
        padding-bottom: 120px;
    }
    .process-section {
        padding: 8%;
        padding-bottom: 80px;
    }
}
@media screen and (max-width: 768px) {
    .hero-1 {
    .hero-container {
        padding-inline: 5%;
        gap: 5%;
    }
    .card-services .container-service {
        flex-direction: row;
        gap: 3%;
    }
    .section-what-we-do {
        padding-top: 255px;
        padding-inline: 10%;
    }
    .blue-background {
        padding: 5%;
        padding-top: 200px;
        padding-bottom: 100px;
        .container-cta {
            max-width: 100%;
        }
    }
    .process-section {
        padding: 5%;
        padding-bottom: 50px;
        .Process-container {
            flex-direction: column;
            align-items: center;
            .Process-text .single-process {
                gap: 20px;
                height: auto;
                padding-top: 20px;
                .process-paragraph {
                    width: 50%;
                }
            }
            .single-image .image-container img {
                width: 40%;
            }
        }
    }
}



.card-services {
    background-color: red;
    margin-top: 35px;
    padding: 5%; 
    .container-service {
        margin-top: 5%; 
        display: flex;
        flex-direction: column;
      
    .hero-container {
        padding-inline: 5%;
        gap: 5%;
        padding-bottom: 200px;
    }
    .card-services .container-service {
        display: flex;
        flex-direction: column;
        gap: 3%;
        
    }
    .section-what-we-do {
        background-color: #2A2D7C;
        color: white;
        padding-inline: 10%;
        padding: 20px 20px;
        margin: 20px;

        .what-we-offer{
            font-size: 28px;
             width: 100%;
        }
        
        
        
    }
    .blue-background {
        padding: 5%;
        padding-top: 200px;
        padding-bottom: 100px;
        .container-cta {
            max-width: 100%;
        }
    }
    .process-section {
        padding: 5%;
        padding-bottom: 50px;
        .Process-container {
            flex-direction: column;
            align-items: center;
            .Process-text .single-process {
                gap: 20px;
                height: auto;
                padding-top: 20px;
                .process-paragraph {
                    width: 50%;
                }
            }
            .single-image .image-container img {
                width: 40%;
            }
        }
    }
}

}
}










  























