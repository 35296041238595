@import'../../variables/vairables.css';
@import "../../main/main.css";

.hero {
    background: linear-gradient(127.31deg, rgba(0, 0, 0, 0.68) 44.53%, rgba(0, 0, 0, 0) 83.52%), url("/public/assets/img/accounting/accountant.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    max-width: 100%;
    color: white;
    padding-top: 40vh;

    .container {
        display: flex;
        flex-direction: column;
        gap: 17px;

        // .hero-btn1{
        //     a{
        //         color: white;
        //         &:hover{
        //             color: #2A2D7C;
        //         }
               
        //     }
        //     &:hover{
        //         color: #2A2D7C;
        //     }
        // }
    }

}



.services {
    margin: 100px 0;

    .container {

        display: flex;
        flex-direction: column;
        gap: 48px;

        .row {
            display: flex;
            align-items: center;
            gap: 150px;

            .col {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 12px;

                img {
                    width: 100%;
                }
            }
        }

    }

    .services-paragraph {
        max-width: 70%;

    }
}



.about-summary {
    color: #f6f9fe;
    text-align: center;
    padding: 50px 50px;

    .container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        background-color: #2A2D7C;
        padding: 50px 50px;
        border-radius: 2px;
    }
}



@media screen and (max-width: 1020px) {
    .hero {
        text-align: center;
        padding-top: 250px;
        height: 855px;

        .heading {
            font-size: 45px;
        }

        .hero-text {
            width: 100%;
        }
    }

    .about-summary {

        .container {
            display: flex;
            flex-direction: column;
            gap: 24px;
            background-color: #2A2D7C;
            padding: 50px 50px;
            border-radius: 2px;

            h1 {
                font-size: 32px;
            }
        }

    }
}


@media screen and (max-width:540px) {
    .container {
        padding:20px;
        width: 100%;
    }

    .services {
        .container {
            width: 100%;
            padding: 0 20px;

            .row {
                background-color: whitesmoke;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 48px;

                .col {
                    width: 100%;
                }
            }

            .row-reverse {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }

    .about-summary{
        .container{
            width: 100%;
        }
       
    }
}