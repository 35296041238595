.container .row {
  display: flex;
  gap: 25px;
  padding: 3rem 0;
  align-items: flex-start;
}
.container .row .col-1 {
  width: 60%;
}
.container .row .col-1 .featured-image {
  aspect-ratio: 16/9;
}
.container .row .col-2 {
  width: 40%;
}
.container .row .col-2 .our-services {
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.container .row .col-2 .our-services .service {
  background-color: white;
  padding: 18px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 4px;
}
.container .row .col-2 .our-services .service .service-icon {
  font-size: 25px;
  color: #2A2D7C;
}
.container .row .col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  line-height: 1.5;
}
.container .row .col a {
  color: #2A2D7C;
  font-weight: bold;
}
.container .row .col img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.container .row .col .image-2 {
  border-radius: 20px;
}
.container .row .col p {
  font-size: 18px;
}
.container .row .col .title {
  font-size: 24px;
}
.container .row .col .blog-details {
  display: flex;
  align-items: center;
  gap: 25px;
}
.container .row .col .blog-details .detail {
  display: flex;
  align-items: center;
  gap: 12px;
}
.container .row .col .blog-details .detail .detail-icon {
  color: #2A2D7C;
}
.container .row .col .blog-details .detail p {
  font-size: 18px;
  color: #3d3d3d;
}

@media screen and (max-width: 768px) {
  .container .row {
    flex-direction: column;
    gap: 25px;
  }
  .container .row .col {
    width: 100%;
  }
  .container .row .col p {
    font-size: 16px;
  }
  .container .row .col .title {
    font-size: 18px;
  }
  .container .row .col .blog-details {
    flex-direction: column;
    align-items: start;
  }
  .container .row .col .blog-details .detail p {
    font-size: 16px;
  }
}
.blog-detail-loader {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: start;
  height: 100vh;
  overflow: hidden;
  padding: 3rem 0;
  gap: 25px;
}
.blog-detail-loader .content {
  flex: 1 1 300px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.blog-detail-loader .content .featured-image {
  width: 100%;
  height: 350px;
}
.blog-detail-loader .content .metas {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.blog-detail-loader .content .metas .meta {
  flex: 1 1 50px;
  height: 52px;
}
.blog-detail-loader .content .text-content {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}
.blog-detail-loader .content .text-content .text {
  width: 100;
  height: 12px;
}
.blog-detail-loader .services {
  flex: 1 1 200px;
  gap: 25PX;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
  margin: 0;
}
.blog-detail-loader .services .title {
  width: 100%;
  height: 52px;
}
.blog-detail-loader .services .service {
  display: flex;
  align-items: stretch;
  gap: 1rem;
}
.blog-detail-loader .services .service .icon {
  height: 52px;
  width: 52px;
}
.blog-detail-loader .services .service .text {
  flex: 1;
  flex: 1;
}/*# sourceMappingURL=blogs-details.css.map */