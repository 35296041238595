.blog-card {
    display: grid;
    gap: 1rem;
    height: fit-content;
    cursor: pointer;

    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .blog-info {
        display: grid;
        height: fit-content;
        gap: 0.5rem;
    }

    .blog-title {
        font-size: 1rem;
    }

    .description {
        font-size: 12px;
    }

    .blog-date {
        font-size: 0.5rem;
    }

    .blog-meta {
        font-size: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;

        .meta {
            display: flex;
            align-items: center;
            color: gray;
            gap: 0.2rem;
        }
    }
}

a {
    color: inherit;
}

.blogs-archive {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    padding-top: 3rem;

    .blog-card {
        flex: 1 1 300;
    }
}

.page-header {
    padding-top: 200px;
    height: 400px;
    background: url('../../../../public//assets/img/home/hero-img.jpg');
    background-color: rgba(0, 0, 0, 0.515);
    background-blend-mode: overlay;
    color: white;

    .container {
        .content {
            max-width: 600px;
        }
    }
}

.blogs-loader {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 3rem 0;

    .loader {
        flex: 1 1 300px;
        height: 300px;
    }
}


@media screen and (max-width: 1020px) {
    .blogs-archive {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 780px) {
    .blogs-archive {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 540px) {
    .blogs-archive {
        grid-template-columns: 1fr;
    }
}