@import '../../css/variables/vairables.scss';


.container {

    .row {
        display: flex;
        gap: 25px;
        padding: 3rem 0;
        align-items: flex-start;

        .col-1 {
            width: 60%;

            .featured-image {
                aspect-ratio: 16/9;
            }
        }

        .col-2 {
            width: 40%;

            .our-services {
                // padding: 30px;
                border-radius: 2px;
                display: flex;
                flex-direction: column;
                gap: 16px;

                .service {
                    background-color: $White-color;
                    padding: 18px 30px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    border-radius: 4px;

                    .service-icon {
                        font-size: 25px;
                        color: $primary-blue;
                    }
                }
            }
        }

        .col {
            display: flex;
            flex-direction: column;
            gap: 20px;
            line-height: 1.5;

            a {
                color: $primary-blue;
                font-weight: bold;
            }

            img {
                object-fit: cover;
                width: 100%;
            }

            .image-2 {
                border-radius: 20px;
            }

            p {
                font-size: 18px;
            }

            .title {
                font-size: 24px;
            }

            .blog-details {
                display: flex;
                align-items: center;
                gap: 25px;

                .detail {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .detail-icon {
                        color: $primary-blue;
                    }

                    p {
                        font-size: 18px;
                        color: $Black-color;
                    }
                }
            }
        }
    }

}

// responsive layout
@media screen and (max-width: 768px) {
    .container {
        .row {
            flex-direction: column;
            gap: 25px;

            .col {
                width: 100%;

                p {
                    font-size: 16px;
                }

                .title {
                    font-size: 18px;
                }

                .blog-details {
                    flex-direction: column;
                    align-items: start;

                    .detail {
                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

.blog-detail-loader {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: start;
    height: 100vh;
    overflow: hidden;
    padding: 3rem 0;
    gap: 25px;


    .content {
        flex: 1 1 300px;
        display: grid;
        height: fit-content;
        gap: 1rem;

        .featured-image {
            width: 100%;
            height: 350px;
        }

        .metas {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;

            .meta {
                flex: 1 1 50px;
                height: 52px;
            }
        }

        .text-content {
            display: grid;
            height: fit-content;
            gap: 0.5rem;

            .text {
                width: 100;
                height: 12px;
            }
        }
    }

    .services {
        flex: 1 1 200px;
        gap: 25PX;
        display: grid;
        height: fit-content;
        gap: 2rem;
        margin: 0;

        .title {
            width: 100%;
            height: 52px;
        }

        .service {
            display: flex;
            align-items: stretch;
            gap: 1rem;

            .icon {
                height: 52px;
                width: 52px;
            }

            .text {
                flex: 1;
                flex: 1;
            }
        }
    }
}