@import "../variables/vairables.scss";

.contact-us {
  background-color: $White-color;
}

.header-container {
  background: linear-gradient(127.31deg, rgba(0, 0, 0, 0.68) 44.53%, rgba(0, 0, 0, 0) 83.52%), url(../../img/office.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px;
  margin: auto;
  height: 80vh;

  .title-container {
    padding-top: 100px;

    li {
      color: white;
      font-family: "Futura";
    }
    h1 {
      font-family: "Redwing, medium";
      color: white;
      padding-top: 20px;
      font-size: 45px;
    }
    p {
      color: white;
      font-family: "Futura";
    }

    .title {
      display: grid;
      flex-direction: column;
      gap: 12px;
    }
  }
}

.location-form {

  .location {
    .location-text {
      background-color: #f6f9fe;
      padding: 20px;
      border-radius: 10px;
      line-height: 1.5;

      .location-title {
        display: flex;
        gap: 18px;
        padding-bottom: 10px;

        .location-icon {
          color: $primary-blue;
          font-size: 25px;
        }
      }

      h1 {
        font-size: 20px;
        font-family: "Redwing";
      }

      p {
        font-size: 18px;
      }

      .location-header {
        display: flex;
        gap: 15px;
        align-items: center;
      }
    }
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .message {
      font-family: "Futura";
      color: $primary-blue;
      font-size: bold;
    }

    form {
      input, textarea {
        padding: 17px;
      }::placeholder{
        font-family: "Futura";
      }
      .input-field {
        border: 1px solid #3461E233;
        border-radius: 10px;
      }
      textarea {
        resize: none;
        width: 100%;
        height: 170px;
        font-family: "Futura";
      }
    }

    .send-message-btn {
      background-color: $primary-blue;
      color: $White-color;
      font-family: "Futura";
      width: 40%;
      padding: 12px 25px;
      border-radius: 10px;
    }
  }

}

@media screen and (max-width: 1250px) {
  .header-container {
    padding: 20px;
  }
}
