* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.all-content {
  background-color: white;
}

.big-text {
  width: 60%;
}

.other-link {
  text-decoration: none;
  color: #3d3d3d;
}

.hero-section {
  background: linear-gradient(127.31deg, rgba(0, 0, 0, 0.68) 44.53%, rgba(0, 0, 0, 0) 83.52%), url("/public/assets/img/home/hero-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  max-width: 100%;
  color: white;
  padding-top: 40vh;
  position: relative;
  width: 100%;
}

.big-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Redwing";
}
.big-text h2 {
  font-size: 16px;
  font-weight: 400;
}
.big-text h1 {
  font-size: 45px;
  font-family: "Redwing, medium";
}
.big-text p {
  font-family: "Futura";
  font-size: 20px;
}
.big-text button {
  cursor: pointer;
  padding: 15px 35px;
  border-radius: 2px;
  border: 2px solid transparent;
  transition: linear 0.2s;
  font-family: "Futura";
  font-size: 18px;
  background-color: #2A2D7C;
  color: white;
  font-weight: 600;
}
.big-text button:hover {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}
.big-text button i {
  padding-left: 10px;
}

.hero-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.hero-bottom .hero-content {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #f6f9fe;
  margin-top: -50px;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  width: 300px;
  height: 96px;
  padding: 35px 17px;
  transition: linear 0.3s;
  z-index: 10;
}
.hero-bottom .hero-content:hover {
  background-color: #2A2D7C;
  cursor: pointer;
  transition: linear 0.3s;
}
.hero-bottom .hero-content:hover .other-link {
  color: white;
}
.hero-bottom .hero-content:hover i {
  color: white;
}
.hero-bottom .hero-content i {
  font-size: 32px;
  color: #4365AF;
}
.hero-bottom .hero-content h1 {
  font-family: "Redwing";
  font-size: 24px;
  color: #3d3d3d;
}

.why-us {
  display: flex;
  flex-direction: column;
  padding-top: 130px;
}
.why-us .why-content {
  display: flex;
  align-items: center;
  gap: 130px;
}
.why-us .why-content .why-text h1 {
  font-size: 45px;
  font-weight: bold;
  font-family: "Redwing, medium";
  line-height: 100px;
}
.why-us .why-content .why-text h2 {
  text-transform: capitalize;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  font-family: "Redwing";
}
.why-us .why-content .why-text p {
  font-family: "Futura", sans-serif;
  color: #3d3d3d;
  font-size: 20px;
}
.why-us .why-content .why-text a {
  font-size: 20px;
  font-family: "Futura", sans-serif;
  color: #2A2D7C;
  text-decoration: underline;
}
.why-us .why-content .why-img {
  width: 100%;
}
.why-us .why-content .why-img img {
  width: 520px;
}

.subscribe {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #2A2D7C;
  color: white;
  padding: 100px;
  padding-block: 50px;
  gap: 30px;
  margin-top: 50px;
}
.subscribe h1 {
  font-size: 45px;
  font-weight: bold;
  font-family: "Redwing", sans-serif;
  text-align: center;
}
.subscribe p {
  font-size: 20px;
  text-align: center;
  font-family: "Futura", sans-serif;
  padding-top: 20px;
}
.subscribe button {
  cursor: pointer;
  padding: 15px 35px;
  border-radius: 2px;
  border: 2px solid transparent;
  transition: linear 0.2s;
  font-family: "Futura Book", sans-serif;
  font-size: 18px;
  color: #2A2D7C;
}
.subscribe button:hover {
  background-color: transparent;
  border: 2px solid white;
  cursor: pointer;
  transition: linear 0.3s;
  color: white;
}
.subscribe button:hover i {
  color: white;
}
.subscribe button i {
  font-size: 18px;
  color: #2A2D7C;
  padding-left: 5px;
}

.services .services-header h1 {
  font-size: 45px;
  font-weight: bold;
  font-family: "Redwing", sans-serif;
  padding-bottom: 15px;
}
.services .services-header h2 {
  font-family: "Futura", sans-serif;
  color: #3d3d3d;
  font-size: 20px;
  font-weight: 500;
}
.services .service-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding-top: 20px;
}
.services .text-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.services .text-header i {
  font-size: 32px;
  color: #4365AF;
}
.services .text-header h1 {
  font-size: 20px;
  font-family: "Redwing";
}
.services p {
  margin-bottom: 15px;
  font-size: 20px;
  font-family: "Futura", sans-serif;
}

.service-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(42, 45, 124, 0.062745098);
  border-radius: 2px;
  padding-top: 30px;
  width: 100%;
  padding-left: 30px;
  padding-right: 50px;
  padding-bottom: 5px;
  gap: 12px;
  transition: linear 0.3s;
}
.service-text:hover {
  background-color: #2A2D7C;
  border: 2px solid white;
  cursor: pointer;
  color: white;
}
.service-text:hover i {
  color: white;
}

.let-partner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #2A2D7C;
  color: white;
  padding: 100px;
  padding-block: 50px;
  gap: 20px;
}
.let-partner h1 {
  font-size: 45px;
  font-weight: bold;
  font-family: "Redwing, medium";
  text-align: center;
}
.let-partner p {
  font-size: 20px;
  text-align: center;
  font-family: "Futura", sans-serif;
}
.let-partner button {
  cursor: pointer;
  padding: 15px 35px;
  border-radius: 2px;
  border: 2px solid transparent;
  transition: linear 0.2s;
  font-family: "Futura Book", sans-serif;
  font-size: 18px;
  color: #2A2D7C;
}
.let-partner button:hover {
  background-color: transparent;
  border: 2px solid white;
  cursor: pointer;
  transition: linear 0.3s;
  color: white;
}
.let-partner button:hover i {
  color: white;
}
.let-partner button i {
  font-size: 18px;
  color: #2A2D7C;
  padding-left: 5px;
}

.our-partners {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}
.our-partners .image-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  align-items: center;
  gap: 20px;
}
.our-partners .image-container img {
  width: 100px;
}
.our-partners h1 {
  font-size: 45px;
  font-weight: bold;
  font-family: "Redwing, medium";
  padding-bottom: 40px;
}

.contacts {
  padding-top: 100px;
}
.contacts h1 {
  font-size: 45px;
  font-family: "Redwing, medium";
}
.contacts p {
  color: #3d3d3d;
  font-size: 20px;
  font-family: "Futura";
}

.location-form {
  display: flex;
  padding-top: 50px;
  gap: 100px;
  padding-bottom: 60px;
}
.location-form .location {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.location-form > div {
  width: 100%;
}
.location-form > div form {
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.location-form > div form input {
  padding: 17px;
  width: 100%;
  border: 1px solid #3d3d3d;
  font-family: "Futura";
  border-radius: 2px;
  transition: linear 0.3s;
  font-size: 16px;
  font-weight: 400;
}
.location-form > div form .your-message {
  padding-top: 17px;
  padding-bottom: 90px;
}
.location-form > div form button {
  width: 200px;
  font-size: 18px;
  background-color: #2A2D7C;
  color: white;
  border: 1px solid #2A2D7C;
  padding-inline: 30px;
  padding-block: 20px;
}
.location-form > div form button:hover {
  background-color: transparent;
  border: 1px solid #2A2D7C;
  cursor: pointer;
  transition: linear 0.3s;
  color: #2A2D7C;
}
.location-form .location-text {
  background-color: #f6f9fe;
  min-width: 260px;
  height: 160px;
  width: 100%;
  padding-top: 30px;
}
.location-form .location-text h1 {
  font-size: 24px;
  font-family: "Redwing";
}
.location-form .location-text .location-header {
  display: flex;
  gap: 15px;
  align-items: center;
}
.location-form .location-text .location-header i {
  padding: 15px;
  padding-inline: 20px;
  color: white;
  background-color: #2A2D7C;
}

.map {
  width: 100%;
}

@media (max-width: 1550px) {
  .hero-section {
    height: 120vh;
  }
}
@media (max-width: 1100px) {
  .why-us .why-content {
    gap: 30px;
  }
  .why-us .why-content .why-img img {
    width: 420px;
  }
}
@media (max-width: 950px) {
  .hero-bottom {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    row-gap: 20px;
    margin: -30px;
    grid-template-columns: 50% 50%;
    gap: 20px;
    row-gap: 20px;
    margin: -30px;
  }
  .hero-bottom .hero-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: auto;
    height: auto;
    margin: 0;
  }
  .service-text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .location-form {
    gap: 30px;
  }
}
.map {
  margin-bottom: 60px;
}

@media (max-width: 935px) {
  .our-partners .image-container {
    gap: 0px;
  }
  .location-form {
    flex-direction: column;
  }
}
@media (max-width: 850px) {
  .why-us .why-content .why-img img {
    width: 320px;
  }
}
@media (max-width: 780px) {
  .big-text {
    gap: 10px;
  }
  .services .service-content {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 752px) {
  .location-form .location {
    grid-template-columns: 1fr;
  }
  .big-text {
    justify-content: center;
    align-items: center;
    text-align: center;
    left: 20%;
    width: 100%;
  }
  .why-us .why-content {
    flex-direction: column-reverse;
  }
  .why-us .why-content .why-img img {
    width: 100%;
    padding-top: 60px;
  }
}
@media (max-width: 652px) {
  .hero-section {
    text-align: center;
    padding-top: 250px;
    height: 855px;
  }
  .our-partners .image-container {
    grid-template-columns: 20% 20%;
    gap: 60px;
  }
  .services .popup .popup-content {
    width: 90%;
  }
}
@media (max-width: 560px) {
  .subscribe {
    padding: 10px;
  }
  .subscribe h1 {
    font-size: 30px;
  }
  .subscribe p {
    font-size: 16px;
  }
  .let-partner {
    padding: 10px;
  }
  .let-partner h1 {
    font-size: 30px;
  }
  .let-partner p {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .trust {
    padding: 0 40px;
  }
  .hero-bottom {
    grid-template-columns: 40% 40%;
  }
}/*# sourceMappingURL=home.css.map */