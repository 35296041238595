.trust {
  max-width: 1200px;
  padding: 0;
  margin: auto;
}

.link {
  text-decoration: none;
  color: white;
}

.header {
  transition: linear 0.3s;
  background-color: transparent;
  margin-bottom: -100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.header.scrolled {
  background-color: white;
}
.header.scrolled li {
  color: rgb(42, 45, 124);
}
.header.scrolled .hamburger div {
  background-color: #2a2d7c;
}
.header.scrolled .link {
  color: rgb(42, 45, 124);
}

.notification {
  background-color: #2a2d7c;
  padding: 8px;
}
.notification a {
  text-decoration: none;
  color: white;
  font-family: "Futura";
  font-size: 20px;
}
.notification a span {
  text-decoration: underline;
}

.nav-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}
.nav-header .logo img {
  width: 150px;
}
.nav-header .nav-menu {
  display: flex;
  align-items: center;
}
.nav-header .nav-menu .links {
  display: flex;
  gap: 25px;
  align-items: center;
}
.nav-header .nav-menu .links ul {
  list-style: none;
  display: flex;
  gap: 20px;
  font-size: 18px;
  color: white;
}
.nav-header .nav-menu .links ul li {
  position: relative;
  cursor: pointer;
}
.nav-header .nav-menu .links ul li:hover .dropdown-menu {
  display: block;
}
.nav-header .nav-menu .dropdown {
  display: flex;
  align-items: center;
  gap: 5px;
}
.nav-header .nav-menu .dropdown span {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.nav-header .nav-menu .dropdown span i {
  padding-left: 5px;
  padding-top: 5px;
}
.nav-header .nav-menu .dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 120%;
  left: -90px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 260px;
  z-index: 1000;
}
.nav-header .nav-menu .dropdown .dropdown-menu li {
  list-style: none;
  padding: 10px;
}
.nav-header .nav-menu .dropdown .dropdown-menu li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  color: #2a2d7c;
  font-family: "Redwing, medium";
}
.nav-header .nav-menu .dropdown .dropdown-menu li a i {
  margin-right: 10px;
  font-size: 25px;
}
.nav-header .nav-menu .dropdown .dropdown-menu li a h3 {
  margin: 0;
  font-size: 20px;
}
.nav-header .hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 11;
}
.nav-header .hamburger div {
  width: 27px;
  height: 4px;
  background-color: #fff;
  margin: 3px 0;
  transition: all 0.3s;
  border-radius: 20px;
}
.nav-header .hamburger.open div {
  background-color: #2a2d7c;
  width: 28px;
}
.nav-header .hamburger.open div:nth-child(1) {
  transform: rotate(40deg) translate(9px, 9px);
}
.nav-header .hamburger.open div:nth-child(2) {
  opacity: 0;
}
.nav-header .hamburger.open div:nth-child(3) {
  transform: rotate(-46deg) translate(5px, -5px);
}

button {
  cursor: pointer;
  padding: 15px 35px;
  border-radius: 2px;
  border: 2px solid transparent;
  transition: linear 0.2s;
  font-family: "Futura Book", sans-serif;
  font-size: 18px;
}
button.blue-bg {
  background-color: rgb(42, 45, 124);
  color: white;
}
button.blue-bg:hover {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}
button.white-bg {
  background-color: white;
  border: 2px solid rgb(42, 45, 124);
}

.second-header {
  transition: linear 0.3s;
  margin-bottom: -100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #eef3fc;
}
.second-header.scrolled {
  background-color: white;
}
.second-header li {
  color: rgb(42, 45, 124);
}
.second-header .white-bg {
  background-color: transparent;
  border: 2px solid rgb(42, 45, 124);
}
.second-header .white-bg:hover {
  background-color: #2a2d7c;
  color: white;
}
.second-header .hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 11;
}
.second-header .hamburger div {
  width: 27px;
  height: 4px;
  background-color: #2a2d7c;
  margin: 3px 0;
  transition: all 0.3s;
  border-radius: 20px;
}
.second-header .hamburger.open div {
  background-color: #2a2d7c;
  width: 28px;
}
.second-header .hamburger.open div:nth-child(1) {
  transform: rotate(40deg) translate(9px, 9px);
}
.second-header .hamburger.open div:nth-child(2) {
  opacity: 0;
}
.second-header .hamburger.open div:nth-child(3) {
  transform: rotate(-46deg) translate(5px, -5px);
}

.second-link {
  text-decoration: none;
  color: #2a2d7c;
}

@media (max-width: 1250px) {
  .trust {
    padding: 0 50px;
  }
}
@media (max-width: 1020px) {
  .nav-header .nav-menu {
    display: none;
  }
  .nav-header .nav-menu .links ul {
    gap: 0px;
    font-weight: 300;
    font-size: 18px;
  }
  .nav-header .hamburger {
    display: flex;
    position: absolute;
    top: 80px;
    right: 20px;
  }
  .nav-header .nav-menu.responsive {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 130px;
    left: 0;
    background-color: white;
    z-index: 100;
  }
  .nav-header .links {
    flex-direction: column;
    align-items: center;
  }
  .nav-header .links ul {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .nav-header .links ul li {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    color: #2a2d7c;
  }
  .nav-header .links ul a button {
    width: 100%;
  }
  .second-header .hamburger {
    display: flex;
    position: absolute;
    top: 40px;
    right: 30px;
  }
  .second-header .nav-menu.responsive {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 90px;
    left: 0;
    background-color: white;
    z-index: 100;
  }
  .second-header .nav-header .links {
    gap: 10px;
  }
}
@media (max-width: 763px) {
  .nav-header .hamburger {
    top: 100px;
  }
  .nav-header .nav-menu.responsive {
    top: 160px;
  }
  .second-header .hamburger {
    top: 40px;
  }
  .second-header .nav-menu.responsive {
    top: 100px;
  }
}
@media (max-width: 445px) {
  .nav-header .hamburger {
    top: 130px;
  }
  .nav-header .nav-menu.responsive {
    top: 190px;
  }
  .second-header .hamburger {
    display: flex;
    position: absolute;
    top: 40px;
    right: 30px;
  }
  .second-header .nav-menu.responsive {
    top: 100px;
  }
}
@media (max-width: 400px) {
  .nav-header .hamburger {
    top: 130px;
  }
  .nav-header .nav-menu.responsive {
    top: 200px;
    padding-top: 50px;
  }
  .second-header .hamburger {
    display: flex;
    position: absolute;
    top: 40px;
    right: 30px;
  }
  .second-header .nav-menu.responsive {
    top: 90px;
  }
}
.white-pages {
  background-color: #2a2d7c;
  position: sticky;
  margin-bottom: 0;
}/*# sourceMappingURL=header.css.map */