* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Futura Book", sans-serif;
  color: #3d3d3d;
  font-size: 20px;
}

.it-support-hero {
  padding: 130px 0;
}
.it-support-hero .container {
  position: relative;
  border-radius: 2px;
  padding: 80px;
  min-height: 350px;
  max-width: 1200px;
  padding: 0;
  margin: auto;
}
.it-support-hero .container .row {
  background-color: #eef3fc;
  display: flex;
  align-items: center;
}
.it-support-hero .container .row .col1 {
  display: grid;
  gap: 24px;
  width: 50%;
}
.it-support-hero .container .row .col1 .quick-support {
  border: 1px solid rgba(42, 45, 124, 0.35);
  padding: 18px;
  display: grid;
  gap: 10px;
  color: #2A2D7C;
  width: 80%;
}
.it-support-hero .container .row .col1 .quick-support .phone-number {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 6px;
  font-weight: bold;
  font-size: 32px;
}
.it-support-hero .container .row .col1 a {
  text-decoration: none;
  color: #3d3d3d;
  cursor: pointer;
}
.it-support-hero .container .row .col1 .heading {
  font-size: 45px;
  font-weight: bold;
}
.it-support-hero .about-the-company {
  padding: 50px 0;
}
.it-support-hero .about-the-company .container {
  text-align: center;
  max-width: 740px;
  display: grid;
  gap: 24px;
}
.it-support-hero .what-we-offer {
  padding: 50px 0;
}
.it-support-hero .what-we-offer .container-row {
  max-width: 1200px;
  padding: 0;
  margin: auto;
}
.it-support-hero .what-we-offer .container-row .main-row {
  display: flex;
  align-items: center;
  gap: 25px;
}
.it-support-hero .what-we-offer .container-row .main-row .col-col-text {
  width: 65%;
  display: grid;
  gap: 24px;
  padding: 50px;
}
.it-support-hero .what-we-offer .container-row-row-text {
  padding: 80px 0;
  gap: 50px;
  display: flex;
  align-items: center;
}
.it-support-hero .what-we-offer .container-row-row-text .text {
  display: grid;
  gap: 12px;
}
.it-support-hero .what-we-offer .container-row-row-text .text .number-title {
  display: flex;
  align-items: center;
  gap: 12px;
}
.it-support-hero .what-we-offer .container-row-row-text .text .number-title .number {
  background-color: #2A2D7C;
  height: 32px;
  width: 32px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.it-support-hero .faq .container-row {
  background-color: #eef3fc;
  padding: 50px;
  max-width: 1200px;
  padding: 0;
  margin: auto;
}
.it-support-hero .faq .container-row .questions {
  display: grid;
  gap: 32px;
  padding: 50px;
}
.it-support-hero .faq .container-row .questions .question-title {
  font-family: "Redwing light";
  color: #3d3d3d;
  font-size: 35px;
}
.it-support-hero .call-to-action {
  padding: 80px;
}
.it-support-hero .call-to-action .container-row {
  display: flex;
  background-color: #2A2D7C;
  color: white;
  flex-direction: column;
  padding: 80px 300px;
  gap: 24px;
  text-align: center;
}
.it-support-hero .contacts {
  padding: 80px;
}
.it-support-hero .contacts .contact-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 0;
  margin: auto;
}
.it-support-hero .contacts .contact-container .contacts-heading {
  padding-bottom: 112px;
}
.it-support-hero .contacts .contact-container .contact-info {
  display: flex;
  justify-content: space-between;
}
.it-support-hero .contacts .contact-container .contact-column {
  margin-top: 50px;
  display: flex;
  align-items: start;
  gap: 30px;
}
.it-support-hero .contacts .contact-container .contact-column .contact-box {
  background-color: #eef3fc;
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.it-support-hero .contacts .contact-container .contact-column .contact-box .office-title {
  display: flex;
  align-items: center;
  gap: 20px;
}
.it-support-hero .contacts .contact-container .contact-column .contact-box .office-title .icon {
  font-size: 28px;
  background-color: #eef3fc;
  color: #2A2D7C;
}
.it-support-hero .contacts .contact-container .contact-details-forms {
  margin-top: -22px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 100px;
}
.it-support-hero .contacts .contact-container .contact-details-forms .form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}
.it-support-hero .contacts .contact-container .contact-details-forms .form-container form {
  display: grid;
  gap: 12px;
}
.it-support-hero .contacts .contact-container .contact-details-forms .form-container form input {
  padding: 15px 30px;
  border: 1px solid grey;
}
.it-support-hero .contacts .contact-container .contact-details-forms .form-container form .submit-btn {
  width: 50%;
  padding: 1px 24px;
  width: 200px;
  font-size: 18px;
  background-color: #2A2D7C;
  color: white;
  border: 1px solid #2A2D7C;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.it-support-hero .contacts .contact-container .contact-details-forms .form-container form .submit-btn:hover {
  background-color: white;
  color: #2A2D7C;
}
.it-support-hero .contacts .contact-container .contact-details-forms .form-container form .submit-btn:active {
  background-color: #2A2D7C;
}
.it-support-hero .contacts .contact-container .contact-details-forms .form-container textarea {
  padding: 18px 39px;
  border-radius: 2px;
  transition: linear 0.3s;
}/*# sourceMappingURL=techsupport.css.map */