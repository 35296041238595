@import '../../variables/vairables.css';
@import "../../main/main.css";
.hero {
  background: linear-gradient(127.31deg, rgba(0, 0, 0, 0.68) 44.53%, rgba(0, 0, 0, 0) 83.52%), url("/public/assets/img/accounting/accountant.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  max-width: 100%;
  color: white;
  padding-top: 40vh;
}
.hero .container {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.services {
  margin: 100px 0;
}
.services .container {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.services .container .row {
  display: flex;
  align-items: center;
  gap: 150px;
}
.services .container .row .col {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.services .container .row .col img {
  width: 100%;
}
.services .services-paragraph {
  max-width: 70%;
}

.about-summary {
  color: #f6f9fe;
  text-align: center;
  padding: 50px 50px;
}
.about-summary .container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #2A2D7C;
  padding: 50px 50px;
  border-radius: 2px;
}

@media screen and (max-width: 1020px) {
  .hero {
    text-align: center;
    padding-top: 250px;
    height: 855px;
  }
  .hero .heading {
    font-size: 45px;
  }
  .hero .hero-text {
    width: 100%;
  }
  .about-summary .container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #2A2D7C;
    padding: 50px 50px;
    border-radius: 2px;
  }
  .about-summary .container h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 540px) {
  .container {
    padding: 20px;
    width: 100%;
  }
  .services .container {
    width: 100%;
    padding: 0 20px;
  }
  .services .container .row {
    background-color: whitesmoke;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }
  .services .container .row .col {
    width: 100%;
  }
  .services .container .row-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .about-summary .container {
    width: 100%;
  }
}/*# sourceMappingURL=accounting.css.map */