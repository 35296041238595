@import'../../variables/vairables.css';
@import "../../main/main.css";


.hero-social-media {
    background-color: #F6F9FE;
    padding-top: 40vh;
    color: #2A2D7C;
    text-align: start;
    position: relative;

    .container {
        .row {
            display: flex;
            align-items: center;
            gap: 24px;

            .col-social {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
            }

            .col {
                width: 50%;

                img {
                    position: absolute;
                    width: 40%;
                    right: 0;
                    top: 190px;
                }
            }

        }

        .social-media {
            cursor: pointer;
            // position: relative;

            img {
                width: 50px;
                height: 50px;
            }

            .facebook {
                position: absolute;
                top: 24vh;
                left: 63vw;
            }



            .instagram {
                position: absolute;
                top: 56vh;
                left: 58vw;
            }

            .linkedin {
                position: absolute;
                top: 38vh;
                left: 60vw;
            }

            .youtube {
                position: absolute;
                top: 72vh;
                left: 55vw;
            }

            .twitter {
                position: absolute;
                top: 90vh;
                left: 50vw;

                #spin {
                    :hover {
                        animation: bounce 0.9s linear;
                    }
                }
            }


        }

        .pattern {
            position: absolute;
            width: 267px;
            height: 311px;
            left: 0;
            top: 700px;
        }
    }
}

.social-media-services {
    background-color: #f6f9fe;
    padding-top: 150px;

    .container {
        .row {
            display: grid;
            grid-template-columns: 30% 30% 30%;
            align-items: start;
            gap: 5%;

            .col {
                width: 100%;
                border-radius: 2px;
                margin: 24px 0;
                display: grid;
                gap: 24px;

                &:hover {
                    padding: 24px;
                    background-color: rgba(52, 97, 226, 0.13);
                    transition: 0.3s;
                    border-radius: 2px;
                    color: #3d3d3d;
                }


            }


        }
    }
}

.social-media-section {
    background-color: #F6F9FE;
    padding: 150px 0 50px 0;

    .container {
        .row {
            display: flex;
            align-items: center;
            gap: 96px;

            .col {
                width: 50%;

                img {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .container {
        padding-left: 5%;
        padding-right: 5%;

        .row {
            .hero-heading {
                font-size: 32px;
            }
        }

    }
}


@media screen and (max-width: 760px) {
    .hero-social-media {
        .container {
            .social-media {
                display: grid;
                gap: 12px;


                img {
                    width: 34px;
                    height: 34px;
                }

                .facebook {
                    position: relative;
                    left: 50vw;
                    top: -450px;
                }

                .instagram {
                    position: relative;
                    left: 50vw;
                    top: -450px;
                }

                .linkedin {

                    position: relative;
                    left: 50vw;
                    top: -450px;
                }

                .youtube {
                    position: relative;
                    left: 50vw;
                    top: -450px;
                }

                .twitter {
                    position: relative;
                    left: 50vw;
                    top: -450px;
                }
            }

            .pattern {
                display: none;
            }
        }
    }

    .social-media-services {
        .container {
            .row {
                grid-template-columns: 47% 47%;
                row-gap: 32px;
            }
        }
    }
}

@media screen and (max-width: 540px) {

    .hero-social-media {
        padding-top: 25vh;

        .container {
            // display: block;
            position: relative;


            .row {
                display: flex;
                flex-direction: column;
                gap: 50px;

                .col {
                    width: 100%;

                    img {
                        position: relative;
                        width: 100%;
                        top: 0;
                        right: -20px;
                    }


                }
            }

            .social-media {
                display: grid;
                gap: 12px;
                position: absolute;
                bottom: 160px;

                .facebook {
                    position: relative;
                    left: 0;
                    top: 0;
                    width: 32px;
                    height: auto;
                }

                .instagram {
                    position: relative;
                    left: 0;
                    top: 0;
                    width: 32px;
                    height: auto;
                }

                .linkedin {
                    position: relative;
                    left: 0;
                    top: 0;
                    width: 32px;
                    height: auto;
                }

                .youtube {
                    position: relative;
                    left: 0;
                    top: 0;
                    width: 32px;
                    height: auto;
                }

                .twitter {
                    position: relative;
                    left: 0;
                    top: 0;
                    width: 32px;
                    height: auto;
                }
            }
        }
    }


    .social-media-services {
        .container {
            .row {
                margin-top: 50px;
                grid-template-columns: 100%;
                gap: 32px;

                .col {
                    margin: 0;
                    background-color: rgba(52, 97, 226, 0.13);
                    padding: 30px;

                    &:hover {
                        margin: 0;
                        padding: 30px;
                        background-color: white;
                        transition: 0.3s;
                        border-radius: 2px;
                        color: #3d3d3d;
                    }
                }
            }
        }
    }

    .social-media-section {
        padding: 50px 0;

        .container {
            .row {
                flex-direction: column-reverse;
                gap: 50px;

                .col {
                    width: 100%;

                }
            }
        }
    }

}