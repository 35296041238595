.unsubscription{
  background-color: white;
  width: 100%;
  padding: 2rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
  font-size: medium;
}

.unsubscription .unsub-b{
    background-color: #F9F9F9;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    border-radius: 1px;
  }
  .unsubscription.unsub-b h2 {
    color:rgb(64, 64, 124);
    font-size: 24px;
}
.check {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
.unsub-btn{
    background-color: rgb(64, 64, 124);
    color: white;
    padding: 20px 527px;
    font-size: larger;
}