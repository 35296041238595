// import variables
@import "../../css/variables/vairables.css";

// import and set fonts

// configure main styles for container, buttons, borders and border-radius, forms and more...

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Futura";
  color: #3d3d3d;
  font-size: 20px;
  background-color: white;
  line-height: 1.5;
}

.contact-image {
  max-width: 160px;
}

.container {
  max-width: 1200px;
  padding: 0;
  margin: auto;
}

.heading {
  font-size: 45px;
  font-weight: bold;
  font-family: "Redwing", sans-serif;
}

.hero-text {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Redwing", sans-serif;
}

ul {
  list-style-type: none;
}

.section-intro {
  width: 60%;
}

@font-face {
  font-family: 'Redwing';
  src: url('/public/fonts/Redwing-Light.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/public/fonts/Futura-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Redwing, medium';
  src: url('/public/fonts/Redwing-Medium.otf') format('opentype');
  font-weight: bold;
}


.btn-blue {
  border: 1px solid white;
  background-color: #2A2D7C;
  color: #fff;
  border: solid #2A2D7C;
  padding: 0.9rem 3rem;
  font-size: 1rem;
  // font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    border: 1px solid #2A2D7C;
    background-color: white;
    border-color: #2A2D7C;
    color: #004085;
  }

  &:focus {
    outline: none;
    border: 1px solid white;
    box-shadow: #2A2D7C;
    border-color: #2A2D7C;
  }

  &:active {
    background-color: #004085;
    border-color: #004085;
  }

  &:disabled {
    background-color: #2A2D7C;
    border-color: #2A2D7C;
    cursor: not-allowed;
    opacity: 0.65;
  }
}






.btn-white {
  padding: 0.9rem 3rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: #fff;
  color: #2A2D7C;
  border: 1px solid white;
  font-weight: boldg;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #2A2D7C;
    color: #fff;

  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: white;
  }
}

.btn-hero {
  cursor: pointer;
  padding: 15px 35px;
  border-radius: 2px;
  border: 2px solid transparent;
  transition: linear 0.2s;
  font-family: "Futura";
  font-size: 18px;
  background-color: #2A2D7C;
  color: white;
  font-weight: 600;

  &:hover {
    background-color: transparent;
    border: 2px solid white;
    color: white;
  }

  a {
    color: white;
  }
}

.btn-hero2 {
  cursor: pointer;
  padding: 15px 35px;
  border-radius: 2px;
  border: 2px solid transparent;
  transition: linear 0.2s;
  font-family: "Futura";
  font-size: 18px;
  background-color: #2A2D7C;
  color: white;
  font-weight: 600;

  &:hover {
    background-color: transparent;
    border: 2px solid white;
    color: white;
  }

  a {
    color: white;
  }
}

// .btn-header {
//   cursor: pointer;
//   padding: 15px 35px;
//   border-radius: 2px;
//   border: 2px solid transparent;
//   transition: linear 0.2s;
//   font-family: "Futura Book", sans-serif;
//   font-size: 18px;
//   color: #2A2D7C;

//   &:hover {
//     background-color: transparent;
//     border: 2px solid white;
//     cursor: pointer;
//     transition: linear 0.3s;
//     color: white;

//     i {
//       color: white;
//     }
//   }

//   i {
//     font-size: 18px;
//     color: #2A2D7C;
//     padding-left: 5px;
//   }
// }


a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

h3 {
  font-family: "Redwing light";
}

.icon {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a2d7c;
  color: white;
  border-radius: 2px;
}



@media screen and (max-width: 1250px) {
  .container {
    padding: 0 50px;
  }
}

@media screen and (max-width: 540px) {
  .container {
    width: 100%;
    padding: 20px;
  }
}


.loader {
  background-color: whitesmoke;
  animation: shimmer 2s ease-in-out infinite;
  background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
  background-size: 800px 100px;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.subscription-form {
  background-color: white;
  width: 100%;
  padding: 2rem;
  display: grid;
  height: fit-content;
  gap: 2rem;
  font-size: medium;

  .logo {
    height: 42px;
  }

  .branding {
    background-color: #F9F9F9;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    border-radius: 1px;

    h1 {
      color: #004085;
      font-size: 24px;
    }

  }

}

form {
  display: grid;
  height: fit-content;
  gap: 1rem;
}

.field {
  display: grid;
  gap: 0.5rem;
}

input {
  padding: 16px;
  font-size: inherit;
  border-radius: 2px;
  border: 1px solid rgb(212, 212, 212);

  &::placeholder {
    color: #CECECE;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .popup-content {
    background: white;
    position: relative;
    width: 600px;
    border-radius: 2px;
    padding-top: 2rem;
  }

  .popup-content h2 {
    margin-top: 0;
  }

  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;

    cursor: pointer;
    color: #007aff;
  }
}


.error-message {
  background-color: #FFD4C8;
  border-radius: 2px;
  padding: 1rem;
  font-size: 12px;
}

.success-card {
  padding: 2rem;
  border-radius: 2rem;
  display: grid;
  height: fit-content;
  gap: 1rem;


  .button {
    width: fit-content;
  }
}

.loading-icon {
  margin-right: 0.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}