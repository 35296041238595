@import "../variables/vairables.scss";

footer {
    background-color: #2a2d7c;
    color: $White-color;

    .container {
        h1 {
            font-size: 25px;
            font-weight: bold;
            font-family: "Redwing, medium";
        }

        p {
            font-family: "Futura", sans-serif;
        }

        .row {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .col {
            display: flex;
            flex-direction: column;
            gap: 12px;
            font-size: 18px;

            .services-list {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }

            .products-links {
                display: flex;
                flex-direction: column;
                gap: 12px;
                a {
                    color: $White-color;
                    font-family: "Futura", sans-serif;
                }
            }
        }
    }

    .bottom-footer {
        background-color: #1a1c5e;
        margin-top: 10px;
        padding: 12px;
        font-size: 14px;

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: 18px;
            }

            .menu-links {
                display: flex;
                gap: 16px;

                a {
                    font-family: "Futura", sans-serif;
                    font-size: 18px;
                }
            }

            .social-media-links {
                display: flex;
                gap: 20px;

                a {
                    color: white;
                    border-radius: 2px;
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .footer {
        .container {
            .col {
                display: flex;
                flex-direction: column;
            }
        }

        .copy {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            gap: 25px;
            padding: 12px 20px 0 20px;

            a {
                color: white;
            }

            .footer-text {
                display: flex;
                flex-direction: column-reverse;
                width: 100%;
                gap: 10px;

                .menu-links {
                    .links {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    footer {
        .bottom-footer {
            .container {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }
        }
    }
}

@media screen and (max-width: 540px) {
    footer {
        .bottom-footer {
            .container {
                align-items: start;

                p {
                    font-size: 16px;
                }

                .menu-links {
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
