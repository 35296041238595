* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #f5f7fb;
  font-family: "Futura Book", sans-serif;
  color: #3d3d3d;
  font-size: 20px;
}

.hero-1 .hero-container {
  padding-bottom: 60px;
  display: flex;
  padding-inline: 70px;
  gap: 50px;
  align-items: center;
}
.hero-1 .hero-container .software-text .paragraph {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}
.hero-1 .hero-container .software-text .header-dev {
  font-size: 45px;
  font-weight: bolder;
  font-family: "Redwing, medium";
}
.hero-1 .hero-container .develoment-image {
  width: 100%;
}
.hero-1 .hero-container .develoment-image img {
  width: 100%;
  height: 100%;
}
.hero-1 .card-services {
  margin-top: 35px;
  padding: 60px;
}
.hero-1 .card-services .container-service {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.hero-1 .card-services .container-service > div {
  transition: 0.3s ease-out;
  padding-block: 10px;
  max-width: 400px;
  width: 100%;
}
.hero-1 .card-services .container-service > div:hover {
  background-color: #4365AF;
  padding-left: 20px;
  color: #ffffff;
}
.hero-1 .card-services .container-service > div:hover i {
  background-color: #ffffff;
  color: #2a2d7c;
}
.hero-1 .card-services .container-service h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
.hero-1 .card-services .container-service i {
  height: 50px;
  transition: 0.3s ease-out;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a2d7c;
  color: white;
  border-radius: 2px;
}
.hero-1 .card-services .title-service {
  font-size: 45px;
  font-weight: bolder;
  font-family: "Redwing, medium";
}
.hero-1 .section-what-we-do {
  margin-top: -149px;
  padding: 60px 60px 60px;
}
.hero-1 .section-what-we-do .what-we-offer {
  font-size: 45px;
  font-weight: bold;
  font-family: "Redwing";
}
.hero-1 .section-what-we-do .paragrap-we-offer {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}
.hero-1 .blue-background {
  padding: 60px;
  padding-top: 500px;
  padding-bottom: 200px;
  max-width: 100%;
  background: linear-gradient(180deg, rgba(42, 45, 124, 0.4) 44.53%, rgb(42, 45, 124) 83.52%), url("/public/assets/img/software-dev/accounting-services.jpg");
}
.hero-1 .blue-background .container-cta {
  max-width: 1200px;
  padding: 0;
  margin: auto;
}
.hero-1 .blue-background .container-cta .blue-container {
  flex-direction: column;
  display: flex;
  gap: 24px;
}
.hero-1 .blue-background .container-cta .blue-container .whitetext {
  color: white;
}
.hero-1 .blue-background .container-cta .blue-container .white-paragraph {
  color: white;
}
.hero-1 .blue-background .btn-white {
  width: -moz-fit-content;
  width: fit-content;
}
.hero-1 .process-section {
  padding: 60px;
  background-color: #2A2D7C;
  color: white;
  position: relative;
  padding-bottom: 100px;
}
.hero-1 .process-section .Process-container {
  display: flex;
  align-items: center;
}
.hero-1 .process-section .Process-container .Process-text .single-process {
  display: flex;
  align-items: start;
  gap: 24px;
  height: 120px;
  padding-top: 55px;
}
.hero-1 .process-section .Process-container .Process-text .single-process .number {
  background-color: #f6f9fe;
  color: #2A2D7C;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
.hero-1 .process-section .Process-container .Process-text .single-process .process-paragraph {
  width: 65%;
}
.hero-1 .process-section .Process-container .single-image .image-container img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 38%;
}
.hero-1 .process-section .haeding-proces {
  font-size: 45px;
  font-weight: bolder;
  font-family: "Redwing, medium";
}

/* Media Queries */
@media screen and (min-width: 1440px) {
  .hero-1 .hero-container {
    padding-inline: 12%;
    gap: 12%;
  }
  .card-services .container-service {
    gap: 10%;
  }
  .section-what-we-do {
    padding-inline: 25%;
  }
  .blue-background {
    padding: 12%;
    padding-top: 350px;
    padding-bottom: 160px;
  }
  .process-section {
    padding: 12%;
    padding-bottom: 120px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .hero-1 .hero-container {
    gap: 10%;
  }
  .card-services .container-service {
    gap: 7%;
  }
  .section-what-we-do {
    padding-inline: 20%;
  }
  .blue-background {
    padding: 10%;
    padding-top: 300px;
    padding-bottom: 140px;
  }
  .process-section {
    padding: 10%;
    padding-bottom: 100px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-1 .hero-container {
    padding-inline: 8%;
    gap: 8%;
  }
  .card-services .container-service {
    gap: 5%;
  }
  .section-what-we-do {
    padding-inline: 15%;
  }
  .blue-background {
    padding: 8%;
    padding-top: 250px;
    padding-bottom: 120px;
  }
  .process-section {
    padding: 8%;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .hero-1 .hero-container {
    padding-inline: 5%;
    gap: 5%;
  }
  .hero-1 .card-services .container-service {
    flex-direction: row;
    gap: 3%;
  }
  .hero-1 .section-what-we-do {
    padding-top: 255px;
    padding-inline: 10%;
  }
  .hero-1 .blue-background {
    padding: 5%;
    padding-top: 200px;
    padding-bottom: 100px;
  }
  .hero-1 .blue-background .container-cta {
    max-width: 100%;
  }
  .hero-1 .process-section {
    padding: 5%;
    padding-bottom: 50px;
  }
  .hero-1 .process-section .Process-container {
    flex-direction: column;
    align-items: center;
  }
  .hero-1 .process-section .Process-container .Process-text .single-process {
    gap: 20px;
    height: auto;
    padding-top: 20px;
  }
  .hero-1 .process-section .Process-container .Process-text .single-process .process-paragraph {
    width: 50%;
  }
  .hero-1 .process-section .Process-container .single-image .image-container img {
    width: 40%;
  }
}
@media screen and (max-width: 540px) {
  .hero-1 {
    display: flex;
    flex-direction: column;
  }
  .hero-1 .hero-container {
    padding-inline: 5%;
    gap: 5%;
    padding-bottom: 200px;
  }
  .hero-1 .card-services .container-service {
    display: flex;
    flex-direction: column;
    gap: 3%;
  }
  .hero-1 .section-what-we-do {
    background-color: #2A2D7C;
    color: white;
    padding-inline: 10%;
    padding: 20px 20px;
    margin: 20px;
  }
  .hero-1 .section-what-we-do .what-we-offer {
    font-size: 28px;
    width: 100%;
  }
  .hero-1 .blue-background {
    padding: 5%;
    padding-top: 200px;
    padding-bottom: 100px;
  }
  .hero-1 .blue-background .container-cta {
    max-width: 100%;
  }
  .hero-1 .process-section {
    padding: 5%;
    padding-bottom: 50px;
  }
  .hero-1 .process-section .Process-container {
    flex-direction: column;
    align-items: center;
  }
  .hero-1 .process-section .Process-container .Process-text .single-process {
    gap: 20px;
    height: auto;
    padding-top: 20px;
  }
  .hero-1 .process-section .Process-container .Process-text .single-process .process-paragraph {
    width: 50%;
  }
  .hero-1 .process-section .Process-container .single-image .image-container img {
    width: 40%;
  }
}/*# sourceMappingURL=softwaredev.css.map */