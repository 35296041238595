footer {
  background-color: #2a2d7c;
  color: white;
}
footer .container h1 {
  font-size: 25px;
  font-weight: bold;
  font-family: "Redwing, medium";
}
footer .container p {
  font-family: "Futura", sans-serif;
}
footer .container .row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
footer .container .col {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 18px;
}
footer .container .col .services-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
footer .container .col .products-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
footer .container .col .products-links a {
  color: white;
  font-family: "Futura", sans-serif;
}
footer .bottom-footer {
  background-color: #1a1c5e;
  margin-top: 10px;
  padding: 12px;
  font-size: 14px;
}
footer .bottom-footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .bottom-footer .container p {
  font-size: 18px;
}
footer .bottom-footer .container .menu-links {
  display: flex;
  gap: 16px;
}
footer .bottom-footer .container .menu-links a {
  font-family: "Futura", sans-serif;
  font-size: 18px;
}
footer .bottom-footer .container .social-media-links {
  display: flex;
  gap: 20px;
}
footer .bottom-footer .container .social-media-links a {
  color: white;
  border-radius: 2px;
  font-size: 20px;
}

@media screen and (max-width: 920px) {
  .footer .container .col {
    display: flex;
    flex-direction: column;
  }
  .footer .copy {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 25px;
    padding: 12px 20px 0 20px;
  }
  .footer .copy a {
    color: white;
  }
  .footer .copy .footer-text {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    gap: 10px;
  }
  .footer .copy .footer-text .menu-links .links {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  footer .bottom-footer .container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
@media screen and (max-width: 540px) {
  footer .bottom-footer .container {
    align-items: start;
  }
  footer .bottom-footer .container p {
    font-size: 16px;
  }
  footer .bottom-footer .container .menu-links a {
    font-size: 16px;
  }
}/*# sourceMappingURL=footer.css.map */