// set color variables
$White-color:white; 
$primary-blue:#2A2D7C;
$Black-color:#3d3d3d;
$secondary-blue:#007aff;
$third-blue:#1a1c5e;
$light-blue:#F6F9FE


// set sizes variables

