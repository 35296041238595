.trust {
  max-width: 1200px;
  padding: 0;
  margin: auto;
}

.link {
  text-decoration: none;
  color: white;

}

.header {
  transition: linear 0.3s;
  background-color: transparent;
  margin-bottom: -100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  backdrop-filter: blur(5px);

  &.scrolled {
    background-color: white;

    li {
      color: rgb(42, 45, 124);
    }

    .hamburger {
      div {
        background-color: #2a2d7c;
      }
    }

    .link {
      color: rgb(42, 45, 124);
    }
  }

}

.notification {
  background-color: #2a2d7c;
  padding: 8px;

  a {
    text-decoration: none;
    color: white;
    font-family: "Futura";
    font-size: 20px;

    span {
      text-decoration: underline;
    }
  }
}

.nav-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;

  .logo {
    img {
      width: 150px;
    }
  }

  .nav-menu {
    display: flex;
    align-items: center;

    .links {
      display: flex;
      gap: 25px;
      align-items: center;

      ul {
        list-style: none;
        display: flex;
        gap: 20px;
        font-size: 18px;
        color: white;

        li {
          position: relative;
          cursor: pointer;

          &:hover .dropdown-menu {
            display: block;
          }
        }
      }


    }

    .dropdown {
      display: flex;
      align-items: center;
      gap: 5px;

      span {
        cursor: pointer;
        display: flex;
        align-items: center;

        i {
          padding-left: 5px;
          padding-top: 5px;
        }
      }

      .dropdown-menu {
        display: none;
        position: absolute;
        top: 120%;
        left: -90px;
        padding: 20px;
        background-color: #fff;
        border: 1px solid #ddd;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        width: 260px;
        z-index: 1000;


        li {
          list-style: none;
          padding: 10px;

          a {
            display: flex;
            align-items: center;
            text-decoration: none;
            font-size: 18px;
            font-weight: 400;
            color: #2a2d7c;
            font-family: "Redwing, medium";


            i {
              margin-right: 10px;
              font-size: 25px;
            }

            h3 {
              margin: 0;
              font-size: 20px;

            }
          }
        }
      }
    }
  }

  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 11;

    div {
      width: 27px;
      height: 4px;
      background-color: #fff;
      margin: 3px 0;
      transition: all 0.3s;
      border-radius: 20px;
    }
  }

  .hamburger.open {
    div {
      background-color: #2a2d7c;
      width: 28px;

    }


    div:nth-child(1) {
      transform: rotate(40deg) translate(9px, 9px);
    }

    div:nth-child(2) {
      opacity: 0;
    }

    div:nth-child(3) {
      transform: rotate(-46deg) translate(5px, -5px);
    }
  }
}

button {
  cursor: pointer;
  padding: 15px 35px;
  border-radius: 2px;
  border: 2px solid transparent;
  transition: linear 0.2s;
  font-family: "Futura Book", sans-serif;
  font-size: 18px;



  &.blue-bg {
    background-color: rgb(42, 45, 124);
    color: white;

    &:hover {
      background-color: transparent;
      border: 2px solid white;
      color: white;
    }
  }

  &.white-bg {
    background-color: white;
    border: 2px solid rgb(42, 45, 124);
  }
}


.second-header {
  transition: linear 0.3s;
  margin-bottom: -100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #eef3fc;

  &.scrolled {
    background-color: white;
  }

  li {
    color: rgb(42, 45, 124);
  }

  .white-bg {
    background-color: transparent;
    border: 2px solid rgb(42, 45, 124);

    &:hover {
      background-color: #2a2d7c;
      color: white;
    }
  }



  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 11;

    div {
      width: 27px;
      height: 4px;
      background-color: #2a2d7c;
      margin: 3px 0;
      transition: all 0.3s;
      border-radius: 20px;
    }
  }

  .hamburger.open {
    div {
      background-color: #2a2d7c;
      width: 28px;

    }


    div:nth-child(1) {
      transform: rotate(40deg) translate(9px, 9px);
    }

    div:nth-child(2) {
      opacity: 0;
    }

    div:nth-child(3) {
      transform: rotate(-46deg) translate(5px, -5px);
    }
  }
}




.second-link {
  text-decoration: none;
  color: #2a2d7c;
}




@media (max-width: 1250px) {

  .trust {
    padding: 0 50px;
  }
}

@media (max-width: 1020px) {
  .nav-header {
    .nav-menu {
      display: none;

      .links {
        ul {
          gap: 0px;
          font-weight: 300;
          font-size: 18px;

        }
      }
    }

    .hamburger {
      display: flex;
      position: absolute;
      top: 80px;
      right: 20px;
    }

    .nav-menu.responsive {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      align-items: center;
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 130px;
      left: 0;
      background-color: white;
      z-index: 100;
    }

    .links {
      flex-direction: column;
      //width: 100%;
      align-items: center;

      ul {
        flex-direction: column;
        // width: 100%;

        margin: 0;
        padding: 0;

        li {
          width: 100%;
          text-align: center;
          padding: 10px 0;
          color: #2a2d7c;
        }

        a button {
          width: 100%;
        }
      }
    }


  }

  .second-header {
    .hamburger {
      display: flex;
      position: absolute;
      top: 40px;
      right: 30px;
    }

    .nav-menu.responsive {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      align-items: center;
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 90px;
      left: 0;
      background-color: white;
      z-index: 100;
    }

    .nav-header {
      .links {
        gap: 10px;
      }
    }

  }
}

@media (max-width: 763px) {
  .nav-header {
    .hamburger {
      top: 100px;
    }

    .nav-menu.responsive {
      top: 160px;
    }
  }

  .second-header {
    .hamburger {
      top: 40px;

    }

    .nav-menu.responsive {

      top: 100px;
    }
  }
}

@media (max-width: 445px) {
  .nav-header {
    .hamburger {
      top: 130px;
    }

    .nav-menu.responsive {
      top: 190px;
    }
  }

  .second-header {
    .hamburger {
      display: flex;
      position: absolute;
      top: 40px;
      right: 30px;
    }

    .nav-menu.responsive {

      top: 100px;
    }

  }
}

@media (max-width: 400px) {
  .nav-header {
    .hamburger {
      top: 130px;
    }

    .nav-menu.responsive {
      top: 200px;
      padding-top: 50px;
    }
  }

  .second-header {
    .hamburger {
      display: flex;
      position: absolute;
      top: 40px;
      right: 30px;
    }

    .nav-menu.responsive {

      top: 90px;

    }
  }

}




.white-pages {
  background-color: #2a2d7c;
  position: sticky;
  margin-bottom: 0;
}