.contact-us {
  background-color: white;
}

.header-container {
  background: linear-gradient(127.31deg, rgba(0, 0, 0, 0.68) 44.53%, rgba(0, 0, 0, 0) 83.52%), url(../../img/office.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px;
  margin: auto;
  height: 80vh;
}
.header-container .title-container {
  padding-top: 100px;
}
.header-container .title-container li {
  color: white;
  font-family: "Futura";
}
.header-container .title-container h1 {
  font-family: "Redwing, medium";
  color: white;
  padding-top: 20px;
  font-size: 45px;
}
.header-container .title-container p {
  color: white;
  font-family: "Futura";
}
.header-container .title-container .title {
  display: grid;
  flex-direction: column;
  gap: 12px;
}

.location-form .location .location-text {
  background-color: #f6f9fe;
  padding: 20px;
  border-radius: 10px;
  line-height: 1.5;
}
.location-form .location .location-text .location-title {
  display: flex;
  gap: 18px;
  padding-bottom: 10px;
}
.location-form .location .location-text .location-title .location-icon {
  color: #2A2D7C;
  font-size: 25px;
}
.location-form .location .location-text h1 {
  font-size: 20px;
  font-family: "Redwing";
}
.location-form .location .location-text p {
  font-size: 18px;
}
.location-form .location .location-text .location-header {
  display: flex;
  gap: 15px;
  align-items: center;
}
.location-form .contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.location-form .contact-form .message {
  font-family: "Futura";
  color: #2A2D7C;
  font-size: bold;
}
.location-form .contact-form form input, .location-form .contact-form form textarea {
  padding: 17px;
}
.location-form .contact-form form ::-moz-placeholder {
  font-family: "Futura";
}
.location-form .contact-form form ::placeholder {
  font-family: "Futura";
}
.location-form .contact-form form .input-field {
  border: 1px solid rgba(52, 97, 226, 0.2);
  border-radius: 10px;
}
.location-form .contact-form form textarea {
  resize: none;
  width: 100%;
  height: 170px;
  font-family: "Futura";
}
.location-form .contact-form .send-message-btn {
  background-color: #2A2D7C;
  color: white;
  font-family: "Futura";
  width: 40%;
  padding: 12px 25px;
  border-radius: 10px;
}

@media screen and (max-width: 1250px) {
  .header-container {
    padding: 20px;
  }
}/*# sourceMappingURL=contact.css.map */