.blog-card {
  display: grid;
  gap: 1rem;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}
.blog-card img {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}
.blog-card .blog-info {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}
.blog-card .blog-title {
  font-size: 1rem;
}
.blog-card .description {
  font-size: 12px;
}
.blog-card .blog-date {
  font-size: 0.5rem;
}
.blog-card .blog-meta {
  font-size: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.blog-card .blog-meta .meta {
  display: flex;
  align-items: center;
  color: gray;
  gap: 0.2rem;
}

a {
  color: inherit;
}

.blogs-archive {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  padding-top: 3rem;
}
.blogs-archive .blog-card {
  flex: 1 1 300;
}

.page-header {
  padding-top: 200px;
  height: 400px;
  background: url("../../../../public//assets/img/home/hero-img.jpg");
  background-color: rgba(0, 0, 0, 0.515);
  background-blend-mode: overlay;
  color: white;
}
.page-header .container .content {
  max-width: 600px;
}

.blogs-loader {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 3rem 0;
}
.blogs-loader .loader {
  flex: 1 1 300px;
  height: 300px;
}

@media screen and (max-width: 1020px) {
  .blogs-archive {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 780px) {
  .blogs-archive {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 540px) {
  .blogs-archive {
    grid-template-columns: 1fr;
  }
}/*# sourceMappingURL=blogs.css.map */