* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.about_us {
  background-color: white;
}
.about_us .aboutus_container .section .header-container {
  background: linear-gradient(127.31deg, rgba(0, 0, 0, 0.68) 44.53%, rgba(0, 0, 0, 0) 83.52%), url(../../img/office.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px;
  margin: auto;
}
.about_us .aboutus_container .section .header-container .title-container {
  padding-top: 100px;
}
.about_us .aboutus_container .section .header-container .title-container li,
.about_us .aboutus_container .section .header-container .title-container h1,
.about_us .aboutus_container .section .header-container .title-container p {
  color: white;
}
.about_us .aboutus_container .section .header-container .title-container .title {
  display: grid;
  flex-direction: column;
  gap: 12px;
}
.about_us .aboutus_container .section .container .middle_section {
  padding-top: 100px;
}
.about_us .aboutus_container .section .container .middle_section .image_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about_us .aboutus_container .section .container .middle_section .image_text .text {
  display: flex;
  flex-direction: column;
}
.about_us .aboutus_container .section .container .middle_section .image_text .image {
  width: -moz-fit-content;
  width: fit-content;
  -o-object-fit: cover;
     object-fit: cover;
}
.about_us .aboutus_container .section .container .middle_section .image_text .image img {
  width: 520px;
}
.about_us .aboutus_container .section .container .middle_section .misson {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}
.about_us .aboutus_container .section .container .middle_section .misson .image {
  width: -moz-fit-content;
  width: fit-content;
}
.about_us .aboutus_container .section .container .middle_section .misson .image img {
  width: 520px;
}
.about_us .aboutus_container .section .container .vision_value {
  gap: 30px;
  padding-top: 100px;
}
.about_us .aboutus_container .section .container .vision_value .contents {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  flex: 1;
  gap: 12px;
}
.about_us .aboutus_container .section .container .vision_value .contents .vision {
  border-radius: 2px;
  padding: 50px;
  background-color: #2A2D7C;
  color: #f6f9fe;
}
.about_us .aboutus_container .section .container .vision_value .contents .images {
  width: -moz-fit-content;
  width: fit-content;
}
.about_us .aboutus_container .section .container .vision_value .contents .images img {
  height: 350px;
  width: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}
.about_us .aboutus_container .section .container .vision_value .values {
  border-radius: 2px;
  padding: 50px;
  background-color: #2A2D7C;
  color: #f6f9fe;
}

@media screen and (max-width: 1250px) {
  .about_us .aboutus_container .section .header-container {
    padding: 20px;
  }
}
@media screen and (max-width: 900px) {
  .about_us .aboutus_container .section .container .middle_section {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
  }
  .about_us .aboutus_container .section .container .middle_section .image_text {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .about_us .aboutus_container .section .container .middle_section .image_text .text {
    width: 100%;
  }
  .about_us .aboutus_container .section .container .middle_section .image_text .image img {
    width: 100%;
  }
  .about_us .aboutus_container .section .container .middle_section .misson {
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
  }
  .about_us .aboutus_container .section .container .middle_section .misson .image img {
    width: 100%;
  }
  .about_us .aboutus_container .section .container .vision_value .contents {
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
    align-items: flex-start;
  }
  .about_us .aboutus_container .section .container .vision_value .contents .values {
    width: 90%;
  }
  .about_us .aboutus_container .section .container .vision_value .contents .vision {
    width: 90%;
  }
  .about_us .aboutus_container .section .container .vision_value .contents .images {
    width: 100%;
  }
  .about_us .aboutus_container .section .container .vision_value .contents .images img {
    width: 90%;
  }
}
@media screen and (max-width: 520px) {
  .about_us .aboutus_container .section .who .middle_section .image_text .image img {
    width: 400px;
  }
  .about_us .aboutus_container .section .who .middle_section .misson .image img {
    width: 100%;
  }
}/*# sourceMappingURL=aboutus.css.map */